@font-face {
	font-family: 'simple-calendar';
	src: url('../fonts/simple-calendar.eot?43976014');
	src:
		url('../fonts/simple-calendar.eot?43976014#iefix') format('embedded-opentype'),
		url('../fonts/simple-calendar.woff?43976014') format('woff'),
		url('../fonts/simple-calendar.ttf?43976014') format('truetype'),
		url('../fonts/simple-calendar.svg?43976014#simple-calendar') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^='simcal-icon-']:before,
[class*=' simcal-icon-']:before {
	display: inline-block;
	font-family: 'simple-calendar';
	font-style: normal;
	font-weight: normal;
	margin-right: 0.2em;
	text-decoration: inherit;
	text-align: center;
	width: 1em;
	font-variant: normal;
	text-transform: none;
	margin-left: 0.2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.simcal-icon-spin {
	-moz-animation: spin 2s infinite linear;
	-o-animation: spin 2s infinite linear;
	-webkit-animation: spin 2s infinite linear;
	animation: spin 2s infinite linear;
	display: inline-block;
}

@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@-webkit-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@-o-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@-ms-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.simcal-icon-animate:before {
	transition: all 0.2s ease-in-out;
}

.simcal-icon-rotate-180:before {
	transform: rotate(180deg);
}

.simcal-icon-calendar-empty:before {
	content: '\e800';
}

.simcal-icon-calendar:before {
	content: '\e801';
}

.simcal-icon-calendar-logo:before {
	content: '\e802';
}

.simcal-icon-settings:before {
	content: '\e804';
}

.simcal-icon-toggles:before {
	content: '\e805';
}

.simcal-icon-list:before {
	content: '\e806';
}

.simcal-icon-event:before {
	content: '\e807';
}

.simcal-icon-help:before {
	content: '\e808';
}

.simcal-icon-panel:before {
	content: '\e80a';
}

.simcal-icon-grid:before {
	content: '\e80b';
}

.simcal-icon-google:before {
	content: '\e80c';
}

.simcal-icon-docs:before {
	content: '\e80f';
}

.simcal-icon-hourglass:before {
	content: '\e811';
}

.simcal-icon-globe:before {
	content: '\e812';
}

.simcal-icon-timezones:before {
	content: '\e813';
}

.simcal-icon-warning:before {
	content: '\e815';
}

.simcal-icon-wordpress:before {
	content: '\e814';
}

.simcal-icon-up:before {
	content: '\e80e';
}

.simcal-icon-right:before {
	content: '\e809';
}

.simcal-icon-down:before {
	content: '\e80d';
}

.simcal-icon-left:before {
	content: '\e803';
}

.simcal-icon-spinner:before {
	content: '\e810';
}

.simcal-calendar {
	position: relative;
}

.simcal-powered {
	display: block;
	margin: -10px 0 20px;
}

.simcal-align-left {
	text-align: left;
}

.simcal-align-right {
	text-align: right;
}

.simcal-default-calendar .simcal-current h3 {
	margin: 0;
	padding: 0;
}

.simcal-default-calendar .simcal-nav {
	vertical-align: middle;
}

.simcal-default-calendar .simcal-nav-button {
	background: transparent;
	border: 0;
	box-shadow: none;
	cursor: pointer;
	margin: 0;
	outline: none;
	padding: 0;
	transition: margin 0.2s ease-out;
	width: 100%;
}
.simcal-default-calendar .simcal-nav-button:focus,
.simcal-default-calendar .simcal-nav-button:hover {
	background: transparent;
	border: 0;
	box-shadow: none;
	outline: none;
}
.simcal-default-calendar .simcal-nav-button.simcal-prev:hover {
	margin-left: -10px;
}
.simcal-default-calendar .simcal-nav-button.simcal-next:hover {
	margin-right: -10px;
}
.simcal-default-calendar .simcal-nav-button:disabled {
	cursor: default;
}
.simcal-default-calendar .simcal-nav-button:disabled:hover {
	margin: 0;
	padding: 0;
}

.simcal-default-calendar .simcal-events {
	list-style: none;
	margin-left: 0;
}

.simcal-default-calendar .simcal-events-toggle {
	border: 0;
	box-shadow: none;
	text-align: center;
	width: 100%;
}
.simcal-default-calendar .simcal-events-toggle:active,
.simcal-default-calendar .simcal-events-toggle:focus,
.simcal-default-calendar .simcal-events-toggle:hover {
	border: 0;
	box-shadow: none;
	outline: none;
}

.simcal-default-calendar ul.simcal-attachments,
.simcal-default-calendar ul.simcal-attendees {
	margin: 0;
	padding: 0;
}

.simcal-default-calendar li.simcal-attachment,
.simcal-default-calendar li.simcal-attendee {
	list-style: none;
	margin-bottom: 4px;
}
.simcal-default-calendar li.simcal-attachment:last-child,
.simcal-default-calendar li.simcal-attendee:last-child {
	margin-bottom: 0;
}
.simcal-default-calendar li.simcal-attachment small,
.simcal-default-calendar li.simcal-attendee small {
	opacity: 0.9;
	text-transform: lowercase;
}

.simcal-default-calendar li.simcal-attachment a,
.simcal-default-calendar li.simcal-attendee a,
.simcal-default-calendar .simcal-organizer a {
	border-bottom: 0;
	text-decoration: none;
}

.simcal-default-calendar li.simcal-attachment img,
.simcal-default-calendar li.simcal-attendee img,
.simcal-default-calendar .simcal-organizer img {
	display: inline-block;
	margin: 0 4px;
	max-height: 24px;
	max-width: 24px;
}

.simcal-default-calendar .simcal-tooltip-content {
	font-size: 1.2em;
	line-height: 1.4;
	padding: 5px;
}

.simcal-default-calendar .simcal-ajax-loader {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.simcal-default-calendar .simcal-ajax-loader > i {
	font-size: 48px;
	left: 50%;
	line-height: 1;
	margin: -36px 0 0 -36px;
	position: absolute;
	top: 50%;
}
.simcal-default-calendar .simcal-ajax-loader.simcal-spinner-top > i {
	top: 20%;
}
.simcal-default-calendar .simcal-ajax-loader.simcal-spinner-bottom > i {
	bottom: 20%;
	top: auto;
}

.simcal-default-calendar-light .simcal-nav-button {
	color: rgba(0, 0, 0, 0.6);
}
.simcal-default-calendar-light .simcal-nav-button:disabled {
	color: rgba(255, 255, 255, 0.9) !important;
}
.simcal-default-calendar-light .simcal-nav-button:disabled:hover {
	color: rgba(255, 255, 255, 0.9) !important;
}
.simcal-default-calendar-light .simcal-nav-button:focus,
.simcal-default-calendar-light .simcal-nav-button:hover {
	color: rgba(0, 0, 0, 0.9);
}

.simcal-default-calendar-light .simcal-events-toggle {
	color: rgba(0, 0, 0, 0.6);
}
.simcal-default-calendar-light .simcal-events-toggle:hover {
	background-color: rgba(0, 0, 0, 0.1);
	color: white;
}

.simcal-default-calendar-light .simcal-ajax-loader {
	background-color: rgba(0, 0, 0, 0.1);
}
.simcal-default-calendar-light .simcal-ajax-loader > i {
	color: rgba(0, 0, 0, 0.3);
}

.simcal-default-calendar-dark .simcal-nav-button {
	color: rgba(255, 255, 255, 0.6);
}
.simcal-default-calendar-dark .simcal-nav-button:disabled {
	color: rgba(0, 0, 0, 0.9) !important;
}
.simcal-default-calendar-dark .simcal-nav-button:disabled:hover {
	color: rgba(0, 0, 0, 0.9) !important;
}
.simcal-default-calendar-dark .simcal-nav-button:focus,
.simcal-default-calendar-dark .simcal-nav-button:hover {
	color: rgba(255, 255, 255, 0.9);
}

.simcal-default-calendar-dark .simcal-events-toggle {
	color: rgba(255, 255, 255, 0.6);
}
.simcal-default-calendar-dark .simcal-events-toggle:hover {
	background-color: rgba(255, 255, 255, 0.1);
	color: black;
}

.simcal-default-calendar-dark .simcal-ajax-loader {
	background-color: rgba(255, 255, 255, 0.1);
}
.simcal-default-calendar-dark .simcal-ajax-loader > i {
	color: rgba(255, 255, 255, 0.3);
}

.simcal-default-calendar-list {
	min-height: 100px;
	padding: 10px 0;
	text-align: left;
}
.simcal-default-calendar-list .simcal-calendar-head {
	display: table;
	padding-bottom: 10px;
	width: 100%;
}
.simcal-default-calendar-list .simcal-calendar-head .simcal-nav {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	width: 20%;
}
.simcal-default-calendar-list .simcal-calendar-head .simcal-nav:nth-child(2) {
	width: 60%;
}
.simcal-default-calendar-list .simcal-calendar-head .simcal-current span {
	display: inline-block;
}
.simcal-default-calendar-list .simcal-calendar-head .simcal-hide-header {
	visibility: hidden;
}
.simcal-default-calendar-list dl.simcal-month {
	margin-bottom: 10px;
	padding: 0 10px 10px;
}
.simcal-default-calendar-list dd.simcal-day {
	list-style: none;
	margin: 0;
}
.simcal-default-calendar-list dt.simcal-day-label {
	font-size: 0.9em;
	list-style: none;
	margin: 20px 0 10px;
}
.simcal-default-calendar-list dt.simcal-day-label > span {
	display: inline-block;
	padding: 4px 8px;
}
.simcal-default-calendar-list ul.simcal-events {
	margin: 10px 14px 0;
}
.simcal-default-calendar-list ul.simcal-events li.simcal-event {
	list-style-type: none;
	margin-bottom: 10px;
	word-wrap: break-word;
}
.simcal-default-calendar-list .simcal-event-details {
	font-size: 0.84em;
	line-height: 1.5;
	overflow: hidden;
}
.simcal-default-calendar-list .simcal-event-details p,
.simcal-default-calendar-list .simcal-event-details .simcal-event-description {
	margin: 0 0 1em;
}
.simcal-default-calendar-list .simcal-events-toggle {
	display: inline-block;
	padding: 0;
}
.simcal-default-calendar-list.simcal-default-calendar-light .simcal-events-toggle {
	background-color: rgba(0, 0, 0, 0.1);
}
.simcal-default-calendar-list.simcal-default-calendar-dark .simcal-events-toggle {
	background-color: rgba(255, 255, 255, 0.1);
}
.simcal-default-calendar-list .simcal-calendar-list-compact .simcal-calendar-head .simcal-nav h3 {
	font-size: 1em;
}
